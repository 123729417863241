import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { format } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import { I18nContext } from 'next-i18next';

const DateLocaleContext = createContext({
  format: (date, dateFormat = 'Ppp') => format(date, dateFormat, { locale: de }),
});

export const DateLocaleContextProvider = ({ children }) => {
  const {
    i18n: { language },
  } = useContext(I18nContext);
  const locale = useMemo(() => {
    if (language) {
      switch (language) {
        case 'de':
          return de;
        default:
          return enUS;
      }
    }
    return de;
  }, [language]);

  const formatFunction = useCallback(
    (date, dateFormat = 'Ppp') => format(date, dateFormat, { locale }),
    [locale]
  );
  return (
    <DateLocaleContext.Provider value={{ locale, format: formatFunction }}>
      {children}
    </DateLocaleContext.Provider>
  );
};

export const useDateLocale = () => useContext(DateLocaleContext);

import types from './types';

const get = () => ({ type: types.get });
const getSuccess = documentList => ({ type: types.getSuccess, documentList });
const getFailure = error => ({ type: types.getFailure, error });

export default {
  get,
  getSuccess,
  getFailure,
};

import types from './types';

const reducer = (state = [], action) => {
  switch (action.type) {
    case types.getSuccess:
      return action.documentList;
    default:
      return state;
  }
};

export default reducer;

import { put, call, takeLeading } from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import { GetDocumentList } from '../services/ModerationApi';

function* getLinkedDocuments() {
  try {
    const documents = yield call(GetDocumentList);
    yield put(actions.getSuccess(documents));
  } catch (error) {
    yield put(actions.getFailure(error));
  }
}

function* handleGetLinkedDocuments() {
  yield takeLeading(types.get, getLinkedDocuments);
}

export default { handleGetLinkedDocuments };
